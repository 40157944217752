exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--5-rules-2-0!../../../node_modules/svg-transform-loader/index.js!normalize.css"), "");

// module
exports.push([module.id, ".Polaris-Page{padding:0 1rem!important;max-width:100%!important}.Polaris-DataTable__Cell{padding:.8rem!important;font-size:1.2rem!important}.Polaris-DataTable__Cell--header,.Polaris-DataTable__Cell--total{border-left:1px solid rgba(16,22,26,.1);font-weight:700}.Polaris-DataTable__TableRow td,.Polaris-DataTable__TableRow th{border-left:1px solid rgba(16,22,26,.1)}.Polaris-DataTable__TableRow:nth-child(odd) td,.Polaris-DataTable__TableRow:nth-child(odd) th{background-color:rgba(191,204,214,.2)}body{background:#f5f8fa}._3wLOB{display:block}", ""]);

// exports
exports.locals = {
	"root": "_3wLOB"
};