import React from 'react';
import Home from 'pages/Home';
import Layout from 'components/Layout';
import {DASHBOARD_URL} from 'constants/URL';
import isLoginIn from 'utils/getAuthInfo';

async function action(req) {
  const {authToken} = req.store.getState().runtime;

  if (isLoginIn(req) || authToken) {
    return {
      redirect: DASHBOARD_URL,
    };
  }

  return {
    title:     'Login | Dashboard',
    chunks:    ['home'],
    component: (
      <Layout>
        <Home />
      </Layout>
    ),
  };
}

export default action;
