import Cookies from 'universal-cookie';
import setRuntimeVariable from 'actions/runtime';
import callApi from 'middleware/api';
import {
  env,
  LOGIN_URL,
} from 'constants/api';
import {DASHBOARD_URL} from 'constants/URL';
import {SET_USER_STATE} from 'constants/redux';
import history from '../history';

const cookies = new Cookies();

/**
 * Log in or Register new user
 * @param email {string} - user e-mail
 * @param password {string} - user password
 * Stores data in Redux store `user`
 */
export function login(email, password) {
  return async(dispatch) => {
    await dispatch({
      type:    SET_USER_STATE,
      payload: {
        error: {},
      },
    });

    const data = await dispatch(callApi(LOGIN_URL, 'POST', {email, password}, false));

    if (data.error && data.error.code) {
      if (!data.error.fields) {
        data.error.fields = {
          main: {
            code:    data.error.code,
            message: data.error.message,
          },
        };
      }

      await dispatch({
        type:    SET_USER_STATE,
        payload: {
          error: data.error.fields,
        },
      });
    } else {
      await dispatch({
        type:    SET_USER_STATE,
        payload: {
          error: {},
          data,
        },
      });

      try {
        const timestamp = +new Date() + (data.auth.expires_in * 1000);
        cookies.set('token', data.auth.access_token, {
          path:    '/',
          expires: new Date(timestamp),
          secure:  env === 'production',
        });

        dispatch(setRuntimeVariable({
          name:  'authToken',
          value: data.auth.access_token,
        }));

        history.push(DASHBOARD_URL);
      } catch (e) {
        console.error('SwiftGift: failed to write Cookies.');
        console.error(e);

        await dispatch({
          type:    SET_USER_STATE,
          payload: {
            error: {
              localStorage: {
                code:    'localStorage_error',
                message: 'SwiftGift: failed to write Cookies. Contact support for help.',
              },
            },
          },
        });
      }
    }
  };
}

/**
 * Log out user
 * Removing data from localStorage
 */
export function logout() {
  return async(dispatch) => {
    try {
      await dispatch({
        type:    SET_USER_STATE,
        payload: {
          error: {},
          data:  {},
        },
      });
      cookies.remove('token', {
        path: '/',
      });

      window.location = '/';
    } catch (e) {
      console.error('SwiftGift: failed to Cookies.');
      history.push('/');
    }
  };
}
