exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".n4i43{width:100%;max-width:500px;text-align:center;margin:50px auto 0}._16E8c{padding-bottom:10px}._2vIbA{width:50%;margin:15px auto 0}", ""]);

// exports
exports.locals = {
	"root": "n4i43",
	"row": "_16E8c",
	"button": "_2vIbA"
};