import {connect} from 'react-redux';
import {login} from 'actions/user';
import Login from './index.js';

const mapStateToProps = state => ({
  error: state.user.error,
});


const mapDispatchToProps = dispatch => ({
  auth(email, password) {
    dispatch(login(email, password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
