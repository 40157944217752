import React, {Component} from 'react';
import {Page} from '@shopify/polaris';
import Layout from 'components/Layout';
import Login from 'components/Login';

class Home extends Component {
  render() {
    return (
      <Layout>
        <Page title="Log in">
          <Login />
        </Page>
      </Layout>
    );
  }
}

export default Home;
