import React, {Component} from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Card,
  Form,
  FormLayout,
  TextField,
  Button,
} from '@shopify/polaris';
import s from './styles.scss';

class Login extends Component {
  state = {
    password: '',
    email:    '',
  };

  handleSubmit = (ev) => {
    const {auth} = this.props;

    ev.preventDefault();
    ev.persist();

    const email = ev.target.email.value.trim();
    const password = ev.target.password.value.trim();

    // this.setState({
    //
    // });

    if (!email || !password) {
      // this.setState({
      //   error:          'Please, fill all fields.',
      //   buttonDisabled: false,
      // });
    } else {
      auth(email, password);
    }
  };

  handleInputChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  render() {
    const {error} = this.props;

    return (
      <div className={s.root}>
        <Card sectioned>
          <Form onSubmit={this.handleSubmit}>
            <FormLayout>
              <div className={s.row}>
                <TextField
                  placeholder="Enter your E-mail"
                  type="email"
                  name="email"
                  label="Email"
                  autoComplete={false}
                  value={this.state.email}
                  onChange={value => this.handleInputChange('email', value)}
                  error={error.email ? error.email.message : false}
                />
              </div>
              <div className={s.row}>
                <TextField
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  label="Password"
                  autoComplete={false}
                  value={this.state.password}
                  onChange={value => this.handleInputChange('password', value)}
                  error={error.email ? error.email.message : false}
                />
              </div>
              <div className={s.button}>
                <Button submit>Login</Button>
              </div>
            </FormLayout>
          </Form>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  auth:  pt.func.isRequired,
  error: pt.object.isRequired,
};


export default withStyles(s)(Login);
