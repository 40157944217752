import {API_TEST_URL} from 'constants/api';

/**
 * API-middleware to make auth fetch
 *
 * @param endpoint {string}
 * @param method {string}
 * @param body {null || object}
 * @param needAuth {boolean}
 * @param headers {object}
 * @return async {function(*, *)} with API response (object)
 */
export default function callApi(endpoint, method, body, needAuth, headers = {}) {
  return async(dispatch, getState, {fetch}) => {
    const {
      host,
      authToken,
    } = getState().runtime;

    const config = {
      headers: {
        ...headers,
      },
      method,
    };

    if (method === 'POST' && body) {
      config.body = JSON.stringify(body);
    }

    if (needAuth) {
      if (!authToken) {
        return {
          error: {
            code:    'no_auth_token',
            message: 'Auth required',
          },
        };
      }

      config.headers.Authorization = `Bearer ${authToken}`;
    }

    try {
      const API = `https://${__ENV__ === 'development' ? API_TEST_URL : `${host}/api/v1/`}`;

      const req = await fetch(`${API}${endpoint}`, config);
      const {status} = req;
      return status === 204 ? {status} : await req.json();
    } catch (error) {
      console.error(error);

      return {
        error: {
          code:   'failed_to_fetch',
          fields: {
            email: {
              message: 'Network problem. Try later.',
            },
          },
        },
      };
    }
  };
}
