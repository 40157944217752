import React, {Component} from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {AppProvider} from '@shopify/polaris';
import s from './styles.scss';

class Layout extends Component {
  static propTypes = {
    children: pt.node.isRequired,
  };

  render() {
    return (
      <AppProvider>
        <div className={s.root}>
          {this.props.children}
        </div>
      </AppProvider>
    );
  }
}

export default withStyles(s)(Layout);
